import React from 'react'
//component
import Form from '../components/block/Form'
const TemplatePageContact = () => {
  return (
    <div className='contact'>
      <div className='container'>
        <Form />
      </div>
    </div>
  )
}

export default TemplatePageContact
