import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
//scss
import '../../scss/block/form.scss'

const getData = graphql`
  {
    c: contentfulConfig {
      cgdv {
        slug
      }
    }
  }
`
const Form = () => {
  const data = useStaticQuery(getData)
  return (
    <>
      <form
        className='form contact block'
        name='contact'
        method='POST'
        action='/success-form'
        data-netlify='true'
        netlify-honeypot='bot-field'
      >
        <input type='hidden' name='bot-field' />

        <h2>Vos coordonnées</h2>
        <div className='fieldset field-2'>
          <label htmlFor='name'>Nom</label>
          <input type='text' name='name' aria-label='name' />
        </div>
        <div className='fieldset field-2'>
          <label htmlFor='mail'>Mail</label>
          <input type='mail' name='mail' aria-label='mail' required />
        </div>
        <div className='fieldset field-2'>
          <label htmlFor='phone'>Téléphone</label>
          <input type='text' name='phone' aria-label='phone' required />
        </div>

        <h2>Votre véhicule</h2>
        <div className='fieldset'>
          <label htmlFor='marque'>Marque</label>
          <input type='text' name='marque' aria-label='marque' />
        </div>
        <div className='fieldset'>
          <label htmlFor='modele'>Modele</label>
          <input type='text' name='modele' aria-label='modele' />
        </div>
        <div className='fieldset'>
          <label htmlFor='carburant'>Carburant</label>
          <input type='text' name='carburant' aria-label='carburant' />
        </div>
        <div className='fieldset'>
          <label htmlFor='cyclindre'>Cyclindrée</label>
          <input type='text' name='cyclindre' aria-label='cyclindre' />
        </div>
        <div className='fieldset'>
          <label htmlFor='chassis'>Numéro de chassis</label>
          <input type='text' name='chassis' aria-label='chassis' />
        </div>

        <h2>Commentaire</h2>
        <div className='fieldset  field-12'>
          <label htmlFor='message'>Message</label>
          <textarea name='message' aria-label='message' required />
        </div>

        <div className='fieldSubmit'>
          <div>
            <input type='checkbox' name='check' value='1' required />
            J'accepte
            <Link to={`/${data.c.cgdv.slug}`}>
              {' '}
              les conditions générales de vente
            </Link>
          </div>
          <input type='submit' value='Envoyer' />
        </div>
      </form>
    </>
  )
}

export default Form
